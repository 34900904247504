import React from 'react';
import './RCSMessaging.css'; // Add a CSS file for styles

const RCSMessaging = () => {
    return (
        <div className="rcs-container">
            <header className="rcs-header">
                <h1>RCS Messaging</h1>
                <p>Next-generation communication for modern businesses</p>
            </header>

            <section className="rcs-content">
                <div className="rcs-info">
                    <h2>What is RCS Messaging?</h2>
                    <p>Rich Communication Services (RCS) is the latest evolution in messaging, enabling brands and businesses to deliver engaging, interactive, and feature-rich messages directly to customers. With RCS, you can provide dynamic content, personalized experiences, and real-time updates seamlessly.</p>
                </div>

                <div className="rcs-image">
                    <img 
                        src="https://via.placeholder.com/600x400" 
                        alt="RCS Messaging" 
                        className="rcs-image-style"
                    />
                </div>
            </section>

            <section className="rcs-features">
                <h2>Key Features</h2>
                <ul className="features-list">
                    <li>Rich media support (images, videos, carousels)</li>
                    <li>Real-time interactions and updates</li>
                    <li>Verified sender identities</li>
                    <li>Interactive buttons for quick responses</li>
                    <li>Enhanced analytics and tracking</li>
                </ul>
            </section>

            <section className="rcs-benefits">
                <h2>Why Choose RCS?</h2>
                <div className="benefits-grid">
                    <div className="benefit-item">
                        <img src="https://via.placeholder.com/150" alt="Enhanced User Experience" />
                        <h3>Enhanced User Experience</h3>
                        <p>Engage customers with visually appealing and interactive content.</p>
                    </div>

                    <div className="benefit-item">
                        <img src="https://via.placeholder.com/150" alt="Better Conversions" />
                        <h3>Better Conversions</h3>
                        <p>Drive more conversions with interactive call-to-action buttons.</p>
                    </div>

                    <div className="benefit-item">
                        <img src="https://via.placeholder.com/150" alt="Secure Messaging" />
                        <h3>Secure Messaging</h3>
                        <p>Ensure secure and verified communication with your customers.</p>
                    </div>
                </div>
            </section>

            <footer className="rcs-footer">
                <p>Contact us to explore how RCS Messaging can transform your business communication.</p>
            </footer>
        </div>
    );
};

export default RCSMessaging;
